<template>
  <div class="page">
    <van-cell-group title="收货信息">
      <van-cell :label="order.address" center>
        <template #title>
          <div>
            <span>{{ order.consignee }}</span>
            <span
              class="ml-20 dark b"
              style="font-size: 85%"
              v-text="order.phone"
            ></span>
          </div>
        </template>
        <template #icon>
          <van-icon class="danger pr-10" name="location" size="25"></van-icon>
        </template>
      </van-cell>
      <van-cell
        :is-link="!!order.shipping_no"
        :url="
          'https://www.kuaidi100.com/chaxun?com=ems&nu=' + order.shipping_no
        "
        icon="logistics"
        v-if="order.shipping_no"
      >
        <span v-if="order.is_shipped">
          {{ order.shipping_name }}：{{ order.shipping_no }}
        </span>
        <span class="danger" v-else v-html="order.state"></span>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="货品信息">
      <div :key="index" class="p-10" v-for="(go, index) in order.items">
        <van-card
          :desc="go.addatt"
          :num="go.num"
          :thumb="go.titlepic || 'http://75pop.com/images/no_picture.gif'"
          :title="go.title"
          :price="go.price_text"
          currency=""
          @click="showInfo({ id: go.portal_id })"
        >
        </van-card>
      </div>
    </van-cell-group>
    <van-cell-group :border="false" class="mt-5">
      <van-cell
        :border="false"
        :value="order.order_total_text"
        title="商品总价"
      ></van-cell>
      <van-cell
        :border="false"
        :value="'+ ' + order.shipping_costs_text"
        title="快递费"
      ></van-cell>
      <van-cell
        :border="false"
        :value="'+ ' + order.invoice_tax_text"
        title="发票税"
        v-if="order.invoice_id"
      ></van-cell>
      <van-cell
        :border="false"
        :value="'- ' + order.pretotal_format"
        title="优惠"
        v-if="order.pretotal"
      ></van-cell>
      <van-cell
        :border="false"
        :title="order.is_paid > 0 ? '实付款' : '应付款'"
      >
        <div>
          =
          <span class="danger fs120 bb">{{ order.all_total_text }}</span>
        </div>
      </van-cell>
      <van-cell>
        <van-button
          type="danger"
          block
          @click="formSubmit"
          v-if="!order.is_paid"
          >现在付款 <span v-html="order.all_total_text"></span
        ></van-button>
      </van-cell>
    </van-cell-group>
    <van-cell-group title="订单信息">
      <van-cell :value="order.order_no" title="订单编号"></van-cell>
      <van-cell :value="order.create_time" title="创建时间"></van-cell>
      <van-cell :value="order.pay_time_text" title="付款时间"></van-cell>
      <van-cell
        :value="order.payment"
        title="付款方式"
        v-if="order.is_paid > 0"
      ></van-cell>
      <van-cell :value="order.shipping_time_text" title="发货时间"></van-cell>

      <van-cell :value="order.bz || '无'" title="订单备注"></van-cell>
      <van-cell :value="order.retext || '无'" title="商家留言"></van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import Vue from "vue";
import { Card } from "vant";
Vue.use(Card);

export default {
  name: "userOrder",
  props: ["id"],
  data() {
    return {
      isReady: false,
      showSubBar: false,
      order: {},
      goods: {},
      payfs: [],
      payid: 0,
    };
  },
  methods: {
    init: function () {
      if (!this.id) this.$router.push({ name: "Orders" });
      this.$http.get("user/order", { order_id: this.id }).then((res) => {
        if (res.code == 1) {
          this.order = res.data;
          this.isReady = true;
        }
      });
    },
    delOrder: function () {
      Dialog.confirm({
        message: "删除本订单？",
      })
        .then(() => {
          this.$http
            .post("user/delete_order", { order_id: this.id })
            .then((res) => {
              if (res.code === 1) this.init();
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    formSubmit: function () {
      this.$http.post("user/pay_order", { order_id: this.id }).then((res) => {
        if (res.code) {
          
        } else {
          this.$toast({ message: res.msg });
        }
        console.log(res);
      });
    },
  },
  activated: function () {
    this.init();
  },
};
</script>
